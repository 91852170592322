// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  customerList,
  deleteOrganisation,
  getOrganisations,
} from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const CustomerListPage = () => {
  const [customers, setCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customeId, setCustomerId] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const customerJson = getOrganisations();
  const env = process.env.REACT_APP_ENV;
  // console.log(customerJson);
  // setCustomers(JSON.parse(customerJson));
  // setCustomers(customerJson);

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setCustomerId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle the delete confirmation
  function confirmDelete(id) {
    console.log("Delete confirmed");
    deleteOrganisation(id).then(() => {
      window.location.reload();
    });
    setIsModalOpen(false);
  }

  useEffect(() => {
    getOrganisations().then((data) => {
      console.log(data);
      setCustomers(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Organisation List
            </h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() => navigate("/create-organization")}
            >
              Create
            </button>
          </div>

          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
              {customers.map((customer, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
                >
                  <div
                    className="cursor-pointer flex flex-col items-center"
                    onClick={() =>
                      navigate("/organization-dashboard", {
                        state: {
                          id: customer.id,
                          name: customer.name,
                          image: `https://66-dementia.b-cdn.net/${env}/organisations/images/${
                            customer.id
                          }?${new Date().getTime()}`,
                          address: customer.address,
                          phone_number: customer.phone_number,
                          type: customer.type,
                          website_url: customer.website_url,
                        },
                      })
                    }
                  >
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/organisations/images/${
                        customer.id
                      }?${new Date().getTime()}`}
                      alt={customer.name}
                      className="w-32 h-32 rounded-full mb-4"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150"; // Use a placeholder URL
                      }}
                    />
                    <h3 className="text-xl font-semibold mb-1">
                      {customer.name}
                    </h3>
                    <p className="text-gray-600 mb-1">{customer.type}</p>
                    <p className="italic text-gray-600 mb-4">
                      {customer.website_url}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    
                    <button
                      className=" bg-yellow-500 text-white py-2 px-6 rounded-3xl"
                      onClick={() =>
                        navigate("/create-organization", {
                          state: {
                            id: customer.id,
                            name: customer.name,
                            image: `https://66-dementia.b-cdn.net/${env}/organisations/images/${
                              customer.id
                            }?${new Date().getTime()}`,
                            address: customer.address,
                            phone_number: customer.phone_number,
                            type: customer.type,
                            website_url: customer.website_url,
                          },
                        })
                      }
                    >
                      Update
                    </button>
                    <button
                      className=" bg-red-600 text-white py-2 px-6 rounded-3xl"
                      onClick={() => openModal(customer.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(customeId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <FooterComponent path="/homescreen" />
    </div>
  );
};

export default CustomerListPage;
