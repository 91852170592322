import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import {
  addLessonRating,
  getCompletedLessonByUserAndLesson,
} from "../../apiFunction/ApiFunctions";

const LessonRating = ({ state }) => {
  const [rating, setRating] = useState(0);

  console.log(state);

  const params = { lesson_id: state.lessonId, rating: rating };

  const handleRating = async (value) => {
    setRating(value);
    params.rating = value;

    await addLessonRating(params); // Add rating to the database
  };

  useEffect(() => {
    async function fetchRating() {
      try {
        if (!params || !params.lesson_id) {
          console.error("Invalid parameters: lesson_id is required.");
          return;
        }

        const getRating = await getCompletedLessonByUserAndLesson(
          params.lesson_id
        );

        if (getRating && typeof getRating.rating === "number") {
          setRating(getRating.rating);
        } else if (getRating === null) {
          console.error("Error fetching lesson rating: getRating is null.");
        } else {
          console.error(
            "Error fetching lesson rating: rating is not a number."
          );
        }
      } catch (error) {
        console.error("Error fetching lesson rating:", error);
      }
    }

    fetchRating();
  }, [params.lesson_id]);

  return (
    <>
      <span className="flex justify-center gap-3cursor-pointer">
        {[...Array(5)].map((_, index) => {
          const value = index + 1;
          return (
            <FaStar
              key={value}
              onClick={() => handleRating(value)}
              color={value <= rating ? "#ffc107" : "#e4e5e9"}
              size={40}
              style={{ marginRight: "5px" }}
            />
          );
        })}
      </span>
    </>
  );
};

export default LessonRating;
