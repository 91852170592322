import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { getCompletedLessonByUserAndLesson } from "../../apiFunction/ApiFunctions";

const ShowLessonRating = ({ lessonId }) => {
  const [rating, setRating] = useState(0);

  console.log(lessonId.lessonId);

  const params = { lesson_id: lessonId.lessonId, rating: rating };

  async function fetchRating() {
    if (!lessonId || !lessonId.lessonId) return;

    try {
      const getRating = await getCompletedLessonByUserAndLesson(
        lessonId.lessonId
      );

      if (getRating && typeof getRating.rating === "number") {
        setRating(getRating.rating);
      }
    } catch (error) {
      console.error("Error fetching lesson rating:", error);
    }
  }

  useEffect(() => {
    fetchRating();
  }, [params.lesson_id]);
  return (
    <>
      <span className="flex justify-start gap-2 text-3xl cursor-pointer">
        {[...Array(5)].map((_, index) => {
          const value = index + 1;
          return (
            <FaStar
              key={value}
              color={value <= rating ? "#ffc107" : "#e4e5e9"}
              size={20}
              style={{ marginRight: "5px" }}
            />
          );
        })}
      </span>
    </>
  );
};

export default ShowLessonRating;
