import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
const CourseRating = ({ averageCourseRating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const ratingValue = index + 1;
    if (ratingValue <= averageCourseRating) {
      return (
        <FaStar
          key={index}
          className="text-yellow-500 mr-1"
          title={`Rating: ${averageCourseRating}`}
        />
      );
    } else if (ratingValue - 0.5 <= averageCourseRating) {
      return (
        <FaStarHalfAlt
          key={index}
          className="text-yellow-500 mr-1"
          title={`Rating: ${averageCourseRating}`}
        />
      );
    } else {
      return (
        <FaRegStar
          key={index}
          className="text-gray-400 mr-1"
          title={`Rating: ${averageCourseRating ? averageCourseRating : 0}`}
        />
      );
    }
  });
  return <div className="flex items-center">{stars}</div>;
};
export default CourseRating;
