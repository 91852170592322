// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createCourse,
  createWebinarVideo,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const CreateWebinarVideoPage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  const [id, setId] = useState(state ? state.id : null);
  const [webinarId, setWebinarId] = useState(state ? state.webinarId : null);
  const [webinarTitle, setWebinarTitle] = useState(
    state ? state.webinarTitle : ""
  );
  const [bunnyId, setBunnyId] = useState(state ? state.bunnyId : "");
  const [status, setStatus] = useState("");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  // const [dateTime, setDateTime] = useState("");
  const [loading, setLoading] = useState(false);
  // const [image, setImage] = useState(state ? state.image : "");

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  useEffect(() => {
    // setCourseList(trainingCourses());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare the form data
    const formData = {
      id: state ? state.id : null,
      webinar_id: webinarId,
      bunny_id: bunnyId,
    };

    createWebinarVideo(formData).then(() => {
      navigate(-1);
      setLoading(false);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            <>{state ? "Update" : "Create"}</> Webinar Video
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Webinar Title:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              //   placeholder="Name of Training Course"
              value={state.webinarTitle}
              required
              readOnly
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Bunny Id:
            </label>
            <input
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter the Bunny Id"
              value={bunnyId}
              onChange={(e) => setBunnyId(e.target.value)}
              required
            />
          </div>

          {/* <div className="mb-4">
            <label className="block text-gray-700">Status:</label>
            <select
              className="border border-gray-300 rounded-lg p-2 w-48 mt-1"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="Uploading">Uploading</option>
              <option value="Available">Available</option>
            </select>
          </div> */}

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="bg-gray-300 text-black py-3 px-6 rounded-lg font-medium w-32 h-12 mr-4"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                <>{state.id ? "Update" : "Create"}</>
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateWebinarVideoPage;
