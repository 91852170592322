// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createWebinar,
  deleteWebinar,
  getUpcomingWebinars,
  getWebinars,
} from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";
import moment from "moment";

const UpcomingTrainingWebinarPage = () => {
  const [webinars, setWebinars] = useState([]);
  const [webinarId, setWebinarId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [archiveWebinar, setArchiveWebinar] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function dateFormat(date_time) {
    const date = new Date(date_time);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Ensures 24-hour time format
    });
  }

  const formatDateTime = (isoString) => {
    return moment(isoString).format("MMMM DD, YYYY hh:mm A");
  };

  // Function to open the modal
  function openModal(id, webinar) {
    if (id) {
      console.log("modal open");
      setWebinarId(id);
      setIsModalOpen(true);
    } else {
      console.log("modal open");
      setArchiveWebinar(webinar);
      setIsModalOpen(true);
    }
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setArchiveWebinar(null);
  };

  // Function to handle the delete confirmation
  function confirmDelete(id) {
    console.log("Delete confirmed");
    deleteWebinar(id).then(() => {
      setWebinars((prevWebinars) => prevWebinars.filter((c) => c.id !== id));
      navigate("/upcoming-training-webinars");
    });
    setIsModalOpen(false);
  }

  function archive(webinar) {
    setIsModalOpen(false);

    // setLoading(true);
    const date = new Date();

    const formData = {
      id: webinar.id,
      title: webinar.title,
      description: webinar.description,
      presenter: webinar.presenter,
      date_time: formatDateTime(webinar.date_time),
      duration_minutes: webinar.duration_minutes,
      image: "",
      archive_date: date.toISOString().split("T")[0],
      live_url: webinar.live_url,
    };

    createWebinar(formData).then(() => {
      // Update the state to remove the archived course
      setWebinars((prevWebinars) =>
        prevWebinars.filter((c) => c.id !== webinar.id)
      );

      // setLoading(false);
      navigate("/upcoming-training-webinars");
    });
  }

  useEffect(() => {
    setLoading(true);
    const sessionWebinarData = JSON.parse(
      sessionStorage.getItem("webinarData")
    );
    console.log(sessionWebinarData);
    getUpcomingWebinars()
      .then((data) => {
        const now = new Date();

        // Filter webinars with future date-times
        // const filteredWebinars = data.filter((webinar) => {
        //   const webinarDateTime = new Date(webinar.date_time); // Assuming `dateTime` is the property
        //   return webinarDateTime >= now;
        // });

        setWebinars(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
        // Handle error (e.g., show an error message)
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg p-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Upcoming Training Webinars
            </h2>
          </div>

          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
              {webinars.map((webinar, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between h-full"
                >
                  <div className="flex-grow flex flex-col justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        navigate("/webinar-registration-list", {
                          state: {
                            id: webinar.id,
                            title: webinar.title,
                            subtitle: webinar.description,
                            presentedBy: webinar.presenter,
                            date_time: webinar.date_time,
                          },
                        })
                      }
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/test/webinars/images/${
                          webinar.id
                        }?${new Date().getTime()}`}
                        alt={webinar.title}
                        className="w-full object-cover rounded-lg mb-4 aspect-[16/16]"
                        style={{ minHeight: "300px" }}
                      />
                      <div className="text-center mb-6">
                        <h3 className="text-xl text-customBlue mb-2 cursor-pointer">
                          {webinar.title}
                        </h3>
                        <p className="italic">{webinar.description}</p>
                      </div>
                      <div className="space-y-2">
                        <p>Presented By: {webinar.presenter}</p>
                        <p>Time: {dateFormat(webinar.date_time)}</p>
                        <p>
                          Staff Registered:{" "}
                          {webinar.registered_users
                            ? webinar.registered_users
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-4 flex justify-around items-center space-x-4">
                    <button
                      className="bg-yellow-500 text-white py-2 px-6 rounded-3xl"
                      onClick={() => {
                        navigate("/create-webinar", {
                          state: {
                            id: webinar.id,
                            title: webinar.title,
                            description: webinar.description,
                            presenter: webinar.presenter,
                            date_time: webinar.date_time,
                            live_url: webinar.live_url,
                            duration: webinar.duration_minutes,
                            image: `https://66-dementia.b-cdn.net/test/webinars/images/${webinar.id}`,
                          },
                        });
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="bg-blue-500 text-white py-2 px-6 rounded-3xl"
                      onClick={() => openModal(null, webinar)}
                    >
                      Archive
                    </button>
                    <button
                      className="bg-red-500 text-white py-2 px-6 rounded-3xl"
                      onClick={() => openModal(webinar.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">
                  Confirm {webinarId ? "Delete" : "Archive"}
                </h2>
                <p className="text-gray-600 mb-6">
                  {webinarId
                    ? "Are you sure you want to delete this item?"
                    : "Are you sure you want to Archive this item?"}
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() =>
                      webinarId
                        ? confirmDelete(webinarId)
                        : archive(archiveWebinar)
                    }
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <FooterComponent path={"/homescreen"} />
    </div>
  );
};

export default UpcomingTrainingWebinarPage;
