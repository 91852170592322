// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  deleteLesson,
  deleteQuestion,
  deleteWebinar,
  getLessonsByModule,
  getQuestionsByQuiz,
  getQuizByModule,
  getWebinars,
} from "../../apiFunction/ApiFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const QuizPage = () => {
  const env = process.env.REACT_APP_ENV;

  const location = useLocation();
  const { state } = location;
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setQuestionId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    deleteQuestion(id)
      .then((response) => {
        if (response.ok) {
          setQuestions((prevQuestions) =>
            prevQuestions.filter((question) => question.id !== id)
          );
          setError(null);
          setIsModalOpen(false);
        } else {
          throw new Error("Failed to delete the lesson");
        }
        // window.location.reload();
      })
      .catch((error) => {
        setIsModalOpen(false);
        console.error("Error deleting the lesson:", error);
        setError("Failed to delete the lesson. Please try again.");
      });
  }

  useEffect(() => {
    getQuizByModule(state.module_id)
      .then((data) => {
        if (data) {
          setQuiz(data);
          getQuestionsByQuiz(data.id).then((questions) => {
            console.log(questions);
            const sortedQuestions = questions.sort((a, b) => a.number - b.number);
            setQuestions(sortedQuestions);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching quiz:", error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Quiz Management
            </h2>
            {quiz && (
              <button
                className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
                onClick={() =>
                  navigate("/add-question", {
                    state: {
                      quiz_id: quiz.id,
                      // total_question: questions ? questions.length : 0,
                    },
                  })
                }
              >
                Create Question
              </button>
            )}
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <>
              {!quiz && (
                <div className="flex justify-center items-center mt-8">
                  <h2 className="text-xl mr-2">
                    No Quiz have been created for this Module. Would you like to
                    create one?
                  </h2>
                  <button
                    className="bg-green-500 text-white py-2 px-6 rounded-lg"
                    onClick={() =>
                      navigate("/create-quiz", {
                        state: {
                          ...state,
                        },
                      })
                    }
                  >
                    Create New Quiz
                  </button>
                </div>
              )}

              {quiz && questions.length == 0 && (
                <div className="flex justify-center items-center mt-8">
                  <h2 className="text-xl">
                    No Questions have been created for this Module.
                  </h2>
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
                {quiz &&
                  questions.length > 0 &&
                  questions.map((question, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
                    >
                      <div>
                        <div className="mb-6">
                          <h3 className="text-xl text-black mb-2 cursor-pointer font-semibold">
                            Question {question.number}
                          </h3>
                          <p className="italic mb-4">{question.text}</p>
                        </div>
                        <div className="w-full mt-4 flex justify-around">
                          <button
                            className=" bg-yellow-500 text-white py-2 px-6 rounded-3xl"
                            onClick={() => {
                              navigate("/add-question", {
                                state: {
                                  quiz_id: quiz.id,
                                  id: question.id,
                                  number: question.number,
                                  text: question.text,
                                },
                              });
                            }}
                          >
                            Update
                          </button>
                          <button
                            className=" bg-red-600 text-white py-2 px-6 rounded-3xl"
                            onClick={() => openModal(question.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(questionId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {error && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96 text-center">
              <h3 className="text-xl font-semibold text-red-600">Error</h3>
              <p className="text-gray-700 mt-4">{error}</p>
              <button
                className="mt-6 bg-red-600 text-white px-4 py-2 rounded-lg"
                onClick={() => setError(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>

      <FooterComponent
        path={"/course-details"}
        state={{ id: state.course_id, name: state.course_name }}
      />
    </div>
  );
};

export default QuizPage;
