import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const Rating = ({ averageUserRating }) => {
    const formattedRating = parseFloat(averageUserRating).toFixed(1);
  // Create an array of 5 elements representing the stars
  const stars = Array.from({ length: 5 }, (_, index) => {
    const ratingValue = index + 1;

    if (ratingValue <= formattedRating) {
      return (
        <FaStar
          key={index}
          className="text-yellow-500 mr-1"
          title={`Rating: ${formattedRating}`}
        />
      ); // Fully filled star
    } else if (ratingValue - 0.5 <= formattedRating) {
      return (
        <FaStarHalfAlt
          key={index}
          className="text-yellow-500 mr-1"
          title={`Rating: ${formattedRating}`}
        />
      ); // Half-filled star
    } else {
      return (
        <FaRegStar
          key={index}
          className="text-gray-400 mr-1"
          title={`Rating: ${formattedRating ? formattedRating : 0}`}
        />
      ); // Empty star
    }
  });

  return <div className="flex items-center">{stars}</div>;
};

export default Rating;
