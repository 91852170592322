import React, { useEffect, useState } from "react";
import organisationIcon from "../../images/organisations.png";
import trainingCourseIcon from "../../images/training-course-icon.png";
import supportingMaterialsIcon from "../../images/supporting-materials-icon.png";
import {
  customerList,
  getCourses,
  getUpcomingWebinars,
} from "../../apiFunction/ApiFunctions";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";

const Homescreen = () => {
  const env = process.env.REACT_APP_ENV;
  const [courseList, setCourseList] = useState([]);
  const [webinars, setWebinars] = useState([]);
  const navigate = useNavigate();
  const overdueCustomerCount = customerList("inactive", 1);
  const sessionUser = JSON.parse(sessionStorage.getItem("userdetails"));
  const firstName = sessionUser?.name?.split(" ")[0];
  const [loadingCourse, setLoadingCourse] = useState(true);
  const [loadingWebinar, setLoadingWebinar] = useState(true);

  function handleNavigation(path) {
    navigate(path);
  }

  function dateFormat(date_time) {
    const date = new Date(date_time);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  useEffect(() => {
    getCourses()
      .then((data) => {
        setCourseList(data);
        setLoadingCourse(false);
      })
      .catch((error) => console.error("Error fetching courses:", error));

    getUpcomingWebinars()
      .then((data) => {
        setWebinars(data);
        setLoadingWebinar(false);
      })
      .catch((error) => console.error("Error fetching webinars:", error));
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col mb-8">
      <HeaderComponent
        name={sessionUser ? firstName : "User"}
        // image={
        //   sessionUser
        //     ? `https://66-dementia.b-cdn.net/${env}/users/pictures/${sessionUser.id}`
        //     : ""
        // }
      />

      {/* Statistics Section */}
      <div className="w-full px-4 lg:px-16 mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
          <p className="text-xl text-blue-950">This Week's Revenue</p>
          <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl mt-4">
            £ 25,000
          </p>
        </div>
        <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
          <p className="text-xl text-blue-950">This Month's Revenue</p>
          <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl mt-4">
            £ 125,000
          </p>
        </div>
        <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
          <p className="text-xl text-blue-950">Cancellations</p>
          <p className="bg-red-500 py-2 px-4 text-lg rounded-2xl mt-4">3</p>
        </div>
        <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
          <p className="text-xl text-blue-950">New Monthly Customers</p>
          <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl mt-4">
            36
          </p>
        </div>
        <div className="bg-white rounded-2xl shadow-lg p-6 text-center">
          <p className="text-xl text-blue-950">Average Feedback Score</p>
          <p className="bg-green-500 py-2 px-4 text-lg text-blue-950 rounded-2xl mt-4">
            96%
          </p>
        </div>
        <button
          className="bg-white rounded-2xl shadow-lg p-6 text-center"
          onClick={() => handleNavigation("/expired-subscription-customers")}
        >
          <p className="text-xl text-blue-950">Overdue Customers</p>
          <p className="bg-red-500 py-2 px-4 text-lg rounded-2xl mt-4">
            {overdueCustomerCount}
          </p>
        </button>
        {/* Repeat for other stats */}
      </div>

      {/* Main Content Section */}
      <div className="flex flex-col lg:flex-row px-4 lg:px-16 space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Main Section */}
        <div className="lg:w-3/4 space-y-6 lg:mr-20">
          <section className="bg-white rounded-lg shadow-xl p-6">
            <h2 className="text-2xl lg:text-3xl text-blue-950 font-semibold mb-4">
              Training Courses
            </h2>
            {/* Show loader */}
            <div className="space-y-4">
              {courseList.map((item) => (
                <div
                  key={item.name}
                  className="bg-gray-200 rounded-2xl p-4 flex justify-between items-center"
                >
                  <div className="flex items-center space-x-4">
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/courses/images/${item.id}`}
                      alt="Course"
                      className="w-20 h-20 rounded-lg"
                    />
                    <p className="text-black-800 text-xl">{item.name}</p>
                  </div>
                  <button
                    className="bg-yellow-100 text-yellow-700 text-lg py-2 px-4 rounded-lg w-28"
                    onClick={() =>
                      navigate("/course-details", {
                        state: {
                          id: item.id,
                          name: item.name,
                          description: item.description,
                        },
                      })
                    }
                  >
                    View
                  </button>
                </div>
              ))}
            </div>
          </section>

          <section className="bg-gray-200 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl lg:text-3xl text-blue-950 font-semibold mb-4">
              Upcoming Live Webinars
            </h2>
            {loadingWebinar ? (
              <div className="flex justify-center items-center h-24">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
              </div>
            ) : (
              <div className="space-y-2">
                {webinars.map((item) => (
                  <div
                    key={item.title}
                    className="flex justify-between items-center"
                  >
                    <div className="flex items-center space-x-2">
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${item.id}`}
                        alt="Webinar"
                        className="w-16 h-16 rounded-full mr-8"
                      />
                      <p
                        className="text-black-800 text-xl"
                        onClick={() =>
                          navigate("/webinar-registration-list", {
                            state: {
                              id: item.id,
                              title: item.title,
                              subtitle: item.description,
                              presentedBy: item.presenter,
                              date_time: item.date_time,
                            },
                          })
                        }
                      >
                        {item.title}
                      </p>
                    </div>
                    <p className="text-blue-700 text-xl">
                      {dateFormat(item.date_time)}
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-center mt-4 space-x-4">
              <button
                className="bg-white border border-black text-black py-2 px-6 rounded-3xl text-xl"
                onClick={() => handleNavigation("/create-webinar")}
              >
                Create
              </button>
              <button
                className="bg-white border border-black text-black py-2 px-6 rounded-3xl text-xl"
                onClick={() => handleNavigation("/upcoming-training-webinars")}
              >
                View All
              </button>
            </div>
          </section>
        </div>

        {/* Sidebar */}
        <aside className="lg:w-1/4 flex flex-col space-y-4">
          {[
            {
              icon: organisationIcon,
              label: "Organisations",
              path: "/customers",
            },
            {
              icon: trainingCourseIcon,
              label: "Training Courses",
              path: "/training-courses",
            },
            {
              icon: trainingCourseIcon,
              label: "Historical Webinars",
              path: "/historical-webinars",
            },
            {
              icon: supportingMaterialsIcon,
              label: "Supporting Materials",
              path: "/supporting-materials",
            },
          ].map((item, index) => (
            <button
              key={index}
              className="bg-white rounded-2xl shadow-xl p-6 flex items-center space-x-4 hover:bg-gray-100 lg:ml-10"
              onClick={() => handleNavigation(item.path)}
            >
              <img src={item.icon} alt={item.label} className="w-16" />
              <p className="text-gray-700 sm:text-lg lg:text-xl">
                {item.label}
              </p>
            </button>
          ))}
        </aside>
      </div>
    </div>
  );
};

export default Homescreen;
