import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import HeaderComponent from "./shared/HeaderComponent";
import FooterComponent from "./shared/FooterComponent";
import dementia from "../../images/dementia.png";
import behaviourChange from "../../images/user2.png";
import stressBehaviour from "../../images/user1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteModule, getModules } from "../../apiFunction/ApiFunctions";
import Rating from "./shared/RatingComponent";
// import homeIcon from "../../images/home_icon.png";

const CourseDetailsPage = () => {
  //   const navigate = useNavigate();

  const [modules, setModules] = useState([]);
  const [moduleId, setModuleId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [focusedModule, setFocusedModule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setModuleId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    deleteModule(id).then(() => {
      window.location.reload();
    });
    setIsModalOpen(false);
  }

  function changeFocusedModule(module) {
    // Add the currently focused module back only if it's not null
    const updatedModules = focusedModule
      ? [...modules, focusedModule]
      : [...modules];

    // Remove the newly selected module from the updated modules array
    const remainingModules = updatedModules.filter((m) => m.id !== module.id);

    // Update states
    setModules(remainingModules.sort((a, b) => a.id - b.id));
    setFocusedModule(module);
  }

  useEffect(() => {
    getModules(state.id)
      .then((data) => {
        const sortedData = data.sort((a, b) => a.number - b.number);
        const focused = sortedData[0]; // The first module to be focused
        const remainingModules = sortedData.filter(
          (module) => module.id !== focused.id
        ); // Remove the focused module

        setModules(remainingModules);
        setFocusedModule(focused);
        console.log("data", data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching modules", error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4 mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl text-black ml-[-1%]">{state.name}</h2>
            {/* <div className="space-x-4">
              <button
                className="bg-yellow-500 text-white py-2 px-4 rounded-lg"
                onClick={() =>
                  navigate("/create-training-course", {
                    state: {
                      id: state.id,
                      name: state.name,
                      description: state.description,
                      image: `https://66-dementia.b-cdn.net/${env}/courses/images/${
                        state.id
                      }?${new Date().getTime()}`,
                    },
                  })
                }
              >
                Update Course
              </button>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg"
                onClick={() => openModal(state.id)}
              >
                Delete Course
              </button>
              
            </div> */}
            <button
              className="bg-yellow-600 text-white py-2 px-6 rounded-lg"
              onClick={() =>
                navigate("/create-module", {
                  state: {
                    course_id: state.id,
                    course_name: state.name,
                  },
                })
              }
            >
              Create Module
            </button>
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <>
              {modules.length > 0 || focusedModule ? (
                <div className="w-full flex justify-between items-start mt-6 space-x-6">
                  <div className="flex flex-col w-[45%] space-y-6">
                    {modules.length > 0 ? (
                      modules.map((module, index) => (
                        <div
                          key={index}
                          className={`bg-white rounded-lg shadow-lg flex p-4 items-center cursor-pointer hover:bg-gray-100 ${
                            focusedModule?.id === module.id ? "bg-gray-100" : ""
                          }`}
                          onClick={() => changeFocusedModule(module)}
                        >
                          <img
                            src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                              module.id
                            }?${new Date().getTime()}`}
                            alt={module.title}
                            className="w-24 object-cover rounded-lg aspect-[1/1]"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "https://via.placeholder.com/150"; // Use a placeholder URL
                            }}
                          />
                          <div className="ml-4">
                            <h3 className="font-semibold text-lg">
                              Module {module.number}: {module.title}
                              <Rating averageUserRating={module.average_rating} />
                              {/* {module.average_rating} */}
                            </h3>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="bg-gray-50 rounded-lg shadow-lg flex items-center justify-center p-8 h-[90%]">
                        <p className="text-gray-600 font-semibold">
                          No other modules available. Only available module is
                          shown on the right.
                        </p>
                      </div>
                    )}
                  </div>

                  {focusedModule ? (
                    <div
                      className={`w-full ${
                        modules.length > 1 ? "w-2/3" : "w-full"
                      } bg-white rounded-lg shadow-lg p-6`}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                          focusedModule.id
                        }?${new Date().getTime()}`}
                        alt="Focused Module"
                        className="w-full max-w-[100%] h-auto object-contain rounded-lg"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://via.placeholder.com/150"; // Use a placeholder URL
                        }}
                      />
                      <div className="mt-4">
                        <h2 className="text-2xl font-semibold">
                          Module {focusedModule.number}: {focusedModule.title}
                        </h2>
                        <div className="flex items-center mt-2">
                          {/* <span className="text-gray-600">
                            {focusedModule.duration}
                          </span> */}
                          <span className="text-gray-600 ml-4 text-lg flex">
                            {/* Rating: {focusedModule.average_rating}/5.0 */}
                            <p className="mr-2">Average Rating: </p> <Rating averageUserRating={focusedModule.average_rating} />
                          </span>
                        </div>
                        <ul className="mt-4 list-disc list-inside text-gray-700">
                          <p>{focusedModule.description}</p>
                        </ul>

                        <center>
                          <button
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 w-32"
                            onClick={() => {
                              navigate("/lesson-list", {
                                state: {
                                  id: focusedModule.id,
                                  title: focusedModule.title,
                                  course_id: state.id,
                                  course_name: state.name,
                                },
                              });
                            }}
                          >
                            View
                          </button>
                          <button
                            className="bg-yellow-600 text-white py-2 px-4 rounded-lg mt-4 ml-4 w-32"
                            onClick={() =>
                              navigate("/create-module", {
                                state: {
                                  id: focusedModule.id,
                                  course_id: state.id,
                                  course_name: state.name,
                                  number: focusedModule.number,
                                  title: focusedModule.title,
                                  description: focusedModule.description,
                                  image: `https://66-dementia.b-cdn.net/${env}/modules/images/${
                                    focusedModule.id
                                  }?${new Date().getTime()}`,
                                },
                              })
                            }
                          >
                            Edit
                          </button>
                          <button
                            className="bg-red-600 text-white py-2 px-4 rounded-lg mt-4 ml-4 w-32"
                            onClick={() => openModal(focusedModule.id)}
                          >
                            Delete
                          </button>
                        </center>

                        <center>
                          <button
                            className="bg-green-600 text-white py-2 px-4 rounded-lg mt-4 ml-4 w-64"
                            onClick={() =>
                              navigate("/quiz", {
                                state: {
                                  course_id: state.id,
                                  course_name: state.name,
                                  module_id: focusedModule.id,
                                  module_title: focusedModule.title,
                                },
                              })
                            }
                          >
                            Quiz
                          </button>
                        </center>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex justify-center items-center bg-gray-50 rounded-lg shadow-lg p-6">
                      <p className="text-gray-600">No module is selected.</p>
                    </div>
                  )}
                </div>
              ) : (
                <h1 className="flex justify-center text-2xl">
                  There are no Modules.
                </h1>
              )}
            </>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(moduleId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      <FooterComponent path={"/training-courses"} />
    </div>
  );
};

export default CourseDetailsPage;
