import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const AdminList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <HeaderComponent />

      <div className="container mx-auto p-4">
        <hr className="border-t-2 border-gray-300 mb-4" />
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-semibold text-customBlue">
            Administrator List for {userOrganisationData.name}
          </h1>
         
        </div>
        <p className=" text-gray-500 mb-2">{state.admins.length} Members</p>
        <hr className="border-t-2 border-gray-300 mb-4" />

        <div className="grid grid-cols-1 gap-4 w-full mb-6">
          <div className={`rounded-3xl shadow-lg p-6 `}>
            
            {state.admins.map((user) => (
              <div
                key={user.id}
                className="flex items-center  p-4 border-b last:border-b-0"
              >
                <img
                  src={`https://66-dementia.b-cdn.net/test/users/pictures/${user.id}?${new Date().getTime()}`}
                  alt={`${user.name}`}
                  className="w-16 h-16 rounded-full mr-4"
                />
                <div className="flex-grow">
                  <h2>{user.name}</h2>
                  <p className="text-xs text-gray-500">{user.roles[0]}</p>
                </div>
                <button
                  className="bg-gray-200 px-4 py-2 rounded-lg"
                  onClick={() =>
                    navigate("/adminprofile", {
                      state: {
                        admin: user,
                        locations: state.locations,
                      },
                    })
                  }
                >
                  View Profile
                </button>
              </div>
            ))}
          </div>
        </div>

        <FooterComponent path={-1} state={state} />
      </div>
    </div>
  );
};

export default AdminList;
