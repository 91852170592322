import React from "react";

import firstImg from "./../../images/group.png";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
import FooterComponent from "../admin/shared/FooterComponent";

const UploadSupportingMaterials = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white">
      <HeaderComponent />
      <div className="flex flex-col items-center">
        <main className="flex-grow flex flex-col py-8 space-y-8 mt-[]">
          {/* <h2 className="text-xl font-bold text-center">Upload Favourite Videos</h2>
          <div className="w-full flex justify-center">
            <div className="relative">
              <img
                src={firstImg} // Placeholder image, replace with the actual image source
                alt="Dementia"
                className="rounded-lg shadow-md ml-20"
              />
              <div className="text-center mt-2">
                <p className="font-semibold mb-2">Understanding Dementia</p>
                <p>
                  Understanding what is Dementia and the various Dementia types
                </p>
                <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg mt-4 mb-4 bg-customBlue">
                  Upload Video
                </button>
              </div>
            </div>
          </div> */}

          <div className="w-full grid grid-cols-2 gap-8 px-4">
            <div className="text-center bg-white rounded-xl p-4 shadow-lg">
              <h3 className="font-bold text-lg  mb-2 text-customBlue">
                Upload Research Papers
              </h3>
              {/* <p>Diagnosing Dementia Types</p>
              <p>Authors: Mary Smith and Don Johnston</p>
              <p>Publication Date: 01/04/2022</p> */}
              <button
                className=" px-4 py-2  text-white rounded-lg mt-8 bg-customBlue"
                onClick={() => {
                  navigate("/upload-research-paper");
                }}
              >
                Upload Research Paper
              </button>
            </div>

            {/* <div className="text-center bg-white rounded-xl p-4 shadow-lg">
              <h3 className="font-bold text-lg mb-2 text-customBlue">
                Upload 6D Handbooks
              </h3>
  
              <button
                className=" px-4 py-2  text-white rounded-lg mt-8 bg-customBlue"
                onClick={() => {
                  navigate("/upload-handbooks");
                }}
              >
                Upload 6D Handbook
              </button>
            </div> */}
{/* 
            <div className="text-center bg-white rounded-xl p-4 shadow-lg">
              <h3 className="font-bold text-lg  mb-2 text-customBlue">
                Upload Assessments
              </h3>
              
              <button className=" px-4 py-2  text-white rounded-lg mt-8 bg-customBlue">
                Upload Assessment
              </button>
            </div> */}

            <div className="text-center bg-white rounded-xl p-4  shadow-lg">
              <h3 className="font-bold text-lg mb-2 text-customBlue">
                Upload Documents
              </h3>
              {/* <p>Dementia Diagnosis Process</p>
              <p>Internal process for diagnosing dementia sufferers</p> */}
              <button
                className=" px-4 py-2 text-white rounded-lg mt-8 bg-customBlue"
                onClick={() => {
                  navigate("/upload-pdfdocuments");
                }}
              >
                Upload Document
              </button>
            </div>
          </div>
        </main>

        <FooterComponent path={-1} />
      </div>
    </div>
  );
};

export default UploadSupportingMaterials;
