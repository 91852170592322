import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";

const QuizResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const { quizId, passMark, totalQuestions, correctAnswers, notAnswered } =
    state;

  return (
    <div className="relative min-h-screen bg-white">
      {/* Header */}
      <HeaderComponent />

      {/* Main Content */}
      <main className="mx-auto flex flex-col items-center bg-white  p-6 max-w-4xl w-full">
        {/* Results Circle */}
        <div
          className={`w-24 h-24 ${
            (correctAnswers / totalQuestions) * 100 >= passMark
              ? "bg-green-500"
              : "bg-red-500"
          }  rounded-full flex items-center justify-center text-white text-2xl
         font-bold mb-6`}
        >
          {(correctAnswers / totalQuestions) * 100}%
        </div>

        {/* Results Text */}
        <h2 className="text-lg font-semibold text-gray-800 text-center mb-4">
          {(correctAnswers / totalQuestions) * 100 >= passMark
            ? "Congratulations! You passed! Your score is "
            : "Unfortunately, you did not pass. Your score is "}
          {(correctAnswers / totalQuestions) * 100}%.
        </h2>
        <p className="text-gray-600 text-center mb-2">
          Pass Marks - {passMark}%
        </p>
        <p className="text-gray-600 text-center mb-2">
          Correct Answers - {correctAnswers}
        </p>
        <p className="text-gray-600 text-center mb-2">
          Not Answered - {notAnswered}
        </p>
        <p className="text-gray-600 text-center mb-4">
          Incorrect Answers - {totalQuestions - correctAnswers - notAnswered}
        </p>
        <p className="text-gray-500 text-center mb-6 italic">
          "Great effort! Keep it up and aim for an even higher score next time."
        </p>

        {/* Buttons */}
        <div className="flex flex-col sm:flex-row gap-4">
          {(correctAnswers / totalQuestions) * 100 < passMark && (
            <button
              className="bg-blue-900 text-white text-lg  py-2 px-6 rounded-lg hover:bg-blue-700"
              onClick={() =>
                navigate("/question", {
                  state: {
                    ...state,
                    quizId: quizId,
                    passMark: passMark,
                  },
                })
              }
            >
              Retake Quiz
            </button>
          )}
          {(correctAnswers / totalQuestions) * 100 >= passMark && (
            <button
              className="bg-blue-900 text-white text-lg  py-2 px-6 rounded-lg hover:bg-blue-700"
              onClick={() => {
                const nextModule = state.modules.find(
                  (module) => module.number === state.moduleNumber + 1
                );
                navigate("/lessons", {
                  state: {
                    moduleId: nextModule.id,
                    moduleNumber: nextModule.number,
                    moduleTitle: nextModule.title,
                    courseName: state.courseName,
                    modules: state.modules,
                  },
                });
              }}
            >
              Next Module
            </button>
          )}
        </div>
      </main>
      <div className="absolute bottom-8 left-0 right-0 flex justify-center">
        <button
          className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default QuizResults;
