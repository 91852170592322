// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createAnswer,
  createCourse,
  createQuestion,
  createQuiz,
  createWebinarVideo,
  deleteQuestion,
  getAnswersByQuestion,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const CreateQuestionPage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  const [id, setId] = useState(state ? state.id : null);
  const [questionNumber, setQuestionNumber] = useState(
    state && state.number ? state.number : state.total_question + 1
  );
  const [questionName, setQuestionName] = useState(
    state && state.text ? state.text : ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
    { text: "", isCorrect: false },
  ]);
  // const [answerData, setAnswerData] = useState({
  //   question_id: "",
  //   text: "",
  //   correct: false,
  // });

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  useEffect(() => {
    if (state && state.id) {
      getAnswersByQuestion(state.id)
        .then((answersRes) => {
          console.log("API response answers:", answersRes);

          const sortedAns = answersRes.sort((a, b) => a.id - b.id);

          // Map API response to match the state structure and update state
          const updatedAnswers = sortedAns.map((answer) => ({
            text: answer.text,
            isCorrect: answer.correct,
          }));

          // If there are less than 4 answers, add blank objects to make up the difference
          while (updatedAnswers.length < 4) {
            updatedAnswers.push({ text: "", isCorrect: false });
          }

          setAnswers(updatedAnswers);
        })
        .catch((error) => {
          console.error("Error fetching answers:", error);
        });
    }
  }, [state]);

  const handleInputChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].text = value;
    setAnswers(updatedAnswers);
  };

  const handleCheckboxChange = (index) => {
    const updatedAnswers = answers.map((answer, idx) => ({
      ...answer,
      isCorrect: idx === index, // Only one answer can be correct
    }));
    setAnswers(updatedAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Function to handle the main logic after deleting or skipping delete
    const proceedWithQuestionCreation = () => {
      // Prepare the form data
      const formData = {
        quiz_id: state.quiz_id,
        number: questionNumber,
        text: questionName,
      };

      const hasCorrectAnswer = answers.some(
        (answer) => answer.isCorrect === true
      );

      if (!hasCorrectAnswer) {
        console.error("At least one answer must be marked as correct.");
        setError("At least one answer must be marked as correct.");
        setLoading(false);
      } else {
        console.log("There is at least one correct answer.");

        createQuestion(formData)
          .then((response) => {
            if (response.ok) {
              return response.json(); // Await the JSON response
            } else {
              setError("Failed to create Question");
              throw new Error("Error creating question");
            }
          })
          .then((responseData) => {
            setError(null);
            console.log("answers", answers);

            answers.map((answer) => {
              let answerData = {
                question_id: responseData.id, // Resolved response
                text: answer.text,
                correct: answer.isCorrect,
              };

              console.log("answerData", answerData);

              createAnswer(answerData)
                .then((response) => {
                  if (response.ok) {
                    console.log("Answer created:", response);
                  } else {
                    console.log("Error creating answer");
                    throw new Error("Error creating Answer");
                  }
                })
                .catch((error) => {
                  console.error("Error creating answer:", error);
                  setError("Failed to create answer");
                });
            });
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error creating question:", error);
            setError("Failed to create Question");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    if (id) {
      // If ID is present, delete first
      deleteQuestion(id)
        .then((response) => {
          if (response.ok) {
            proceedWithQuestionCreation();
          } else {
            throw new Error("Failed to delete the question");
          }
        })
        .catch((error) => {
          console.error("Error deleting the question:", error);
          setLoading(false);
        });
    } else {
      // If no ID, proceed directly with question creation
      proceedWithQuestionCreation();
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-4xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            Add Quiz Question
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Question Number:
            </label>
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter Question number"
              value={questionNumber}
              onChange={(e) => setQuestionNumber(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Question Name:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Write your Question here"
              value={questionName}
              onChange={(e) => setQuestionName(e.target.value)}
              required
            />
          </div>

          {answers.map((answer, index) => (
            <div key={index} className="mb-4 flex items-center gap-4">
              {/* Answer Input Box */}
              <input
                type="text"
                value={answer.text}
                onChange={(e) => handleInputChange(index, e.target.value)}
                placeholder={`Answer ${index + 1}`}
                className="w-full border border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-300"
                required
              />
              {/* Checkbox for Correct Answer */}
              <div className="flex items-center gap-2">
                <label className="text-gray-700">Correct?</label>
                <input
                  type="checkbox"
                  checked={answer.isCorrect}
                  onChange={() => handleCheckboxChange(index)}
                  className="h-5 w-5"
                />
              </div>
            </div>
          ))}

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="bg-gray-300 text-black py-3 px-6 rounded-lg font-medium w-32 h-12 mr-4"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                "Save Question"
              )}
            </button>
          </div>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded relative w-1/3 ml-[33.33%] text-center mt-2"
              role="alert"
            >
              <span className="block  sm:inline">Error : {error}</span>
            </div>
          )}
        </form>
      </main>
    </div>
  );
};

export default CreateQuestionPage;
