import React, { useEffect } from "react";
import logo from "../../../images/dementia-logo-removebg-preview.png"; // Adjust the path as needed
import profileImage from "../../../images/profile-image.png"; // Adjust the path as needed
import homeIcon from "../../../images/icon-camera.png";
import { useNavigate } from "react-router-dom";

const HeaderComponent = ({ name, image = "" }) => {
  const topValue = "0%";
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("userdetails"));

  const handleLogout = () => {
    // Clear session data (example: local storage or session storage)
    localStorage.clear(); // Clear all local storage
    sessionStorage.clear(); // Clear all session storage

    navigate("/login");
  };

  return (
    <header className="px-6 mt-4 mb-4">
      {/* First Row: Logo, Heading, and Home Icon */}
      <div className="flex items-center justify-between">
        {/* Logo */}
        <div className="flex flex-col items-center space-y-2">
          <button
            onClick={() =>
              navigate(
                user.roles[0] === "Administrator"
                  ? "/homescreen"
                  : user.roles[0] === "OrganisationAdministrator"
                  ? "/managerhomescreen"
                  : "/dashboard"
              )
            }
          >
            <img src={logo} alt="6D Dementia Training" className="w-24" />
          </button>
        </div>

        {/* Heading */}
        <h1 className="text-3xl font-medium text-customBlue text-center flex-1">
          6D Dementia Training
        </h1>

        {/* Home Icon */}
        <button
          className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600"
          onClick={() => handleLogout()}
        >
          Logout
        </button>
      </div>

      {/* Second Row: Profile Section */}
      {name && (
        <div className="flex flex-col space-y-2 mt-4">
          {/* <img
            src={image ? image : profileImage}
            alt="Profile"
            className="w-16 h-16 rounded-full"
          /> */}
          <span className="text-blue-600">Welcome, {name}</span>
        </div>
      )}
    </header>
  );
};

export default HeaderComponent;
