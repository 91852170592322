import React, { useEffect } from "react";
import Header from "./Header";
// import module1 from "../../images/coursedetails/1.png";
import { useLocation, useNavigate } from "react-router-dom";
import playerjs from "player.js";
import { createCompletedLesson } from "../../apiFunction/ApiFunctions";
import HeaderComponent from "../admin/shared/HeaderComponent";
import LessonRating from "./LessonRating";

const MediaPlayer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));

  useEffect(() => {
    const player = new playerjs.Player("iframe-video");
    // console.log(player);
    player.on("ready", () => {
      player.on("ended", () => {
        // console.log("Video has ended");
        if (state.type === "course") {
          const completedLesson = {
            user_id: user.id,
            lesson_id: state.lessonId,
          };
          createCompletedLesson(completedLesson)
            .then((data) => {
              console.log("completedlesson", data);
            })
            .catch((error) => {
              console.error("Error created completedlesson", error);
            });
        }
      });
    });
  }, [state, user.id]);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <div className="min-h-screen py-8 flex flex-col items-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="lg:w-1/4">
              <h2 className="text-3xl font-bold mb-5">{state.title}</h2>
              {state.type === "course" && (
                <p className="text-2xl mb-4">
                  Module {state.moduleNumber}: <br />
                  {state.moduleTitle}
                </p>
              )}
              {state.type === "course" && (
                <p className="text-2xl mb-4">
                  Topic {state.lessonNumber}: <br />
                  {state.lessonTitle}
                </p>
              )}
              {state.type === "webinar" && (
                <p className="text-2xl font-medium mb-4">{state.subTitle}</p>
              )}
              {state.type === "webinar" && (
                <p className="text-2xl font-light mb-4">
                  Presented By : {state.presenter}
                </p>
              )}
            </div>
            <div className="lg:w-3/4">
              <div className="relative pt-[56.25%]">
                <iframe
                  id="iframe-video"
                  src={`https://iframe.mediadelivery.net/embed/324584/${state.videoId}?autoplay=false`}
                  loading="lazy"
                  className="border-none absolute top-0 h-full w-full"
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>

              {state.type === "course" && (
                <div className="my-5 text-center">
                  <span className="flex gap-4 justify-center items-center font-semibold text-xl text-gray-700">
                    {user.roles[0] !== "Administrator" &&
                      user.roles[0] !== "OrganisationAdministrator" && (
                        <div>
                          <p className="mb-2">Rate This Topic</p>
                          <LessonRating state={state} />
                        </div>
                      )}
                  </span>
                </div>
              )}
              <div className="mt-12 flex justify-center">
                <button
                  className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;
