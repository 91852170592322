// import organisationIcon from "../images/organisations.png"; // Adjust the path as needed
import courseIcon1 from "../images/Image(2).png"; // Adjust the path as needed
import courseIcon2 from "../images/Image(3).png"; // Adjust the path as needed
import courseIcon3 from "../images/Image(4).png"; // Adjust the path as needed
import courseIcon4 from "../images/Image(5).png"; // Adjust the path as needed
import customerIcon1 from "../images/Loughview care home.png"; // Adjust the path as needed
import customerIcon2 from "../images/Greenfield NHS trust.png"; // Adjust the path as needed
import customerIcon3 from "../images/Lakeside care home.png"; // Adjust the path as needed
import customerIcon4 from "../images/Sunnyside care home.png"; // Adjust the path as needed
import user1 from "../images/user1.png";
import user2 from "../images/user2.png";
import user3 from "../images/user3.png";
import user4 from "../images/user4.png";
import user5 from "../images/user5.png";
import user6 from "../images/user6.png";
import ecd from "../images/ECD.png";
import pain from "../images/Pain.png";
import ucb from "../images/UCB.png";
import { baseUrl } from "../environment";
// import usersIcon from "../images/users.png"; // Adjust the path as needed

export function userInfo(role = "", length = 3) {
  const users = [
    {
      name: "Test User",
      firstname: "Test",
      lastname: "User",
      email: "test@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user1,
    },
    {
      name: "Care Home Admin",
      firstname: "Care Home",
      lastname: "Admin",
      email: "testmanager@example.com",
      password: "123456",
      role: "manager",
      designation: "Manager",
      image: user1,
    },
    {
      name: "Brendan Crossey",
      firstname: "Brendan",
      lastname: "Crossey",
      email: "brendan@example.com",
      password: "123456",
      role: "admin",
      designation: "Owner",
      image: user3,
    },
    {
      name: "Merry Mchom",
      firstname: "Merry",
      lastname: "Mchom",
      email: "merry@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user2,
    },
    {
      name: "Martin Jhonston",
      firstname: "Martin",
      lastname: "Jhonston",
      email: "martin@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user4,
    },
    {
      name: "Sarah Jhonson",
      firstname: "Sarah",
      lastname: "Jhonson",
      email: "sarah@example.com",
      password: "123456",
      role: "admin",
      designation: "Doctor",
      image: user5,
    },
    {
      name: "Fred Smyth",
      firstname: "Fred",
      lastname: "Smyth",
      email: "fred@example.com",
      password: "123456",
      role: "admin",
      designation: "Manager",
      image: user6,
    },
    {
      name: "John Doe",
      firstname: "John",
      lastname: "Doe",
      email: "john@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user1,
    },
    {
      name: "James Smith",
      firstname: "James",
      lastname: "Smith",
      email: "james@example.com",
      password: "123456",
      role: "user",
      designation: "Demnetia Nurse",
      image: user2,
    },
    {
      name: "Sanjay Das",
      firstname: "Sanjay",
      lastname: "Das",
      email: "sanjay@example.com",
      password: "123456",
      role: "user",
      designation: "Nurse",
      image: user3,
    },
    {
      name: "Dhiraj Bharadwaj",
      firstname: "Dhiraj",
      lastname: "Bharadwaj",
      email: "dhiraj@example.com",
      password: "123456",
      role: "user",
      designation: "Doctor",
      image: user4,
    },
    {
      name: "Aurobinda De",
      firstname: "Aurobinda",
      lastname: "De",
      email: "ari@example.com",
      password: "123456",
      role: "user",
      designation: "Doctor",
      image: user5,
    },
  ];
  if (role && role === "admin") {
    return users.filter((user) => user.role === "admin").slice(0, length);
  } else if (role && role === "user") {
    return users.filter((user) => user.role === "user").slice(0, length);
  }
  return users;
}

export function trainingCourses() {
  const courses = [
    {
      id: 1,
      title: "6D Dementia Training for Care Staff",
      description: [
        "Understanding dementia and changes in behaviour",
        "Responding to changes in behaviour to reduce distress",
        "Tools to help understand changes in behaviour: looking for patterns",
        "The experience of the person with dementia living in a care home",
        "The challenges experienced by care staff in a care home, balancing the many demands on your time",
        "Meeting the needs of the resident with dementia and supporting their family",
        "Case examples",
        "Psychological wellbeing for care staff",
      ],
      image: courseIcon1,
      plays: 187,
    },
    {
      id: 2,
      title: "Dementia Training for Family Carers",
      description: [
        "Understanding dementia and changes in behaviour",
        "Responding to changes in behaviour to reduce distress",
        "Tools to help understand changes in behaviour: looking for patterns",
        "Case Examples",
        "Challenges of caring for someone who has dementia",
        "Psychological wellbeing for carers",
      ],
      image: courseIcon2,
      plays: 256,
    },
    {
      id: 3,
      title: "6D Dementia Team Training",
      description: [
        "Understanding dementia and changes in behaviour",
        "Looking at all the dimensions that are important to understand the person",
        "Seeing the perspective of the person with dementia and those who offer care and support. What do we do when these are different?",
        "Case studies",
        "How best to record behaviour to facilitate understanding",
        "The challenges experienced by staff and how to address these challenges",
      ],
      image: courseIcon3,
      plays: 312,
    },
    {
      id: 4,
      title: "6D Dementia Specialist Team Training",
      description: [
        "Implementing 6D Dementia within your team",
        "The processes that need to be in place to ensure good outcomes",
        "How to engage staff who will implement recommendations",
        "How to write recommendations to ensure that everyone who supports the person with dementia knows what to do to reduce potential distress",
      ],
      image: courseIcon4,
      plays: 198,
    },
  ];
  return courses;
}

export function completedCourse() {
  const completedCourses = [
    {
      title: "6D Training for Teams",
      date: "1st January 2023",
      score: "95%",
      modules: 6,
      image: courseIcon1,
    },
    {
      title: "6D Specialist Team Training",
      date: "15th February 2023",
      score: "88%",
      modules: 7,
      image: courseIcon2,
    },
    {
      title: "6D Advanced Team Training",
      date: "10th March 2023",
      score: "98%",
      modules: 6,
      image: courseIcon3,
    },
    {
      title: "6D Advanced Team Training 2",
      date: "10th March 2023",
      score: "98%",
      modules: 6,
      image: courseIcon4,
    },
  ];
  return completedCourses;
}

export function activeCourse() {
  const activeCourses = [
    {
      title: "6D Training for Teams",
      progress: 80,
      staffMembers: 10,
      renewalDate: "1st March 2025",
      status: "Subscribed",
    },
    {
      title: "6D Specialist Team Training",
      progress: 50,
      staffMembers: 24,
      renewalDate: "1st July 2024",
      status: "Expired",
    },
    {
      title: "6D Advanced Team Training",
      progress: 30,
      staffMembers: 12,
      renewalDate: "1st December 2024",
      status: "Subscribed",
    },
  ];
  return activeCourses;
}

export function award() {
  const awards = [
    { date: "7/5/23", title: "6D Training for Teams" },
    { date: "11/05/24", title: "6D Specialist Team Training" },
  ];
  return awards;
}

export async function getLocationById(email, password, id) {
  const response = await fetch(
    `${baseUrl}/locations/getLocationById?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${email}:${password}`,
      },
    }
  );

  if (response.ok) {
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getOrganisationByLocation(email, password, id) {
  const response = await fetch(
    `${baseUrl}/organisations/getOrganisationByLocation?location_id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${email}:${password}`,
      },
    }
  );

  if (response.ok) {
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getCourseCompletionsByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/courses/getCourseCompletionsByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function createModule(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "modules/updateModule" : "modules/createModule";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create organisation:", response.statusText);
  //   return response;
  // }
  return response;
}

export async function getModules(course_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/modules/getModulesByCourse?course_id=${course_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function deleteModule(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/modules/deleteModule?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (!response.ok) {
    console.error("Failed to delete module:", response.statusText);
  }
  return response;
}

export async function createLesson(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "lessons/updateLesson" : "lessons/createLesson";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create organisation:", response.statusText);
  }
}

// add lesson rating
export async function addLessonRating(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const user_id = sessionUserData.id;

  const response = await fetch(
    `${baseUrl}/completedLessons/setCompletedLesson`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
      body: JSON.stringify({ ...params, user_id }),
    }
  );

  return response;
}

// get lesson rating
export async function getCompletedLessonByUserAndLesson(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const user_id = sessionUserData.id;

  const response = await fetch(
    `${baseUrl}/completedLessons/getCompletedLessonByUserAndLesson?user_id=${user_id}&lesson_id=${params}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Rating not submited successfully", response.statusText);
  }
}

export async function createLessonVideo(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id
    ? "lessonVideos/updateLessonVideo"
    : "lessonVideos/createLessonVideo";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  return response;
}

export async function getLessonVideoByLesson(lessonId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/lessonVideos/getLessonVideoByLesson?lesson_id=${lessonId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function deleteLessonVideo(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/lessonVideos/deleteLessonVideo?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to delete video:", response.statusText);
  }
  return response;
}

export async function getLessonsByModule(module_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/lessons/getLessonsByModule?module_id=${module_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get lessons:", response.statusText);
  }
}

export async function deleteLesson(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/lessons/deleteLesson?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (!response.ok) {
    console.error("Failed to delete module:", response.statusText);
  }
  return response;
}

// Create and Update Webinar
export async function createWebinar(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "webinars/updateWebinar" : "webinars/createWebinar";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function createWebinarVideo(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id
    ? "webinarVideos/updateWebinarVideo"
    : "webinarVideos/createWebinarVideo";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create webinar video:", response.statusText);
  // }
  return response;
}

export async function getWebinars() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(`${baseUrl}/webinars/getWebinars`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function deleteWebinar(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/webinars/deleteWebinar?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (!response.ok) {
    console.error("Failed to delete webinar:", response.statusText);
  }
  return response;
}

export async function getUserWebinarsByWebinar(webinar_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/userWebinars/getUserWebinarsByWebinar?webinar_id=${webinar_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getUpcomingWebinars() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(`${baseUrl}/webinars/getUpcomingWebinars`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function getHistoricalWebinars() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(`${baseUrl}/webinars/getHistoricalWebinars`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  return response;
}

export async function getWebinarsByTitleContains(title_contains) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/webinars/getWebinarsByTitleContains?value=${title_contains}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function getUpcomingWebinarsByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/webinars/getUpcomingWebinarsByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function getUpcomingWebinarsByLocation(locationId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/webinars/getUpcomingWebinarsByLocation?location_id=${locationId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function getUserWebinarsByWebinarAndOrganisation(webinarId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/userWebinars/getUserWebinarsByWebinarAndOrganisation?webinar_id=${webinarId}&organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function getCourseAverageScoresByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  // console.log(userOrganisationData)
  const response = await fetch(
    `${baseUrl}/courses/getCourseAverageScoresByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getAssessmentScoresByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  // console.log(userOrganisationData)
  const response = await fetch(
    `${baseUrl}/userCourses/getAssessmentScoresByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getUserCompletionsByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/courses/getUserCompletionsByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch user completions:", response.statusText);
  }
}

export async function getTotalUsersByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/users/getTotalUsersByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getCompletedPercentageByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/userCourses/getCompletedPercentageByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getUsersByOrganisation(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = id
    ? null
    : JSON.parse(sessionStorage.getItem("organisationDetails"))[0];
  const response = await fetch(
    `${baseUrl}/users/getUsersByOrganisation?organisation_id=${
      id ? id : userOrganisationData.id
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getLocationsByOrganisation(organisation_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = organisation_id
    ? null
    : JSON.parse(sessionStorage.getItem("organisationDetails"))[0];
  const response = await fetch(
    `${baseUrl}/locations/getLocationsByOrganisation?organisation_id=${
      organisation_id ? organisation_id : userOrganisationData.id
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getOrganisationSubscriptionsByOrganisation(
  organisation_id
) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = organisation_id ? null : JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const url = organisation_id
    ? `organisationSubscriptions/getOrganisationSubscriptionsByOrganisation?organisation_id=${organisation_id}`
    : `organisationSubscriptions/getOrganisationSubscriptionsByOrganisation?organisation_id=${userOrganisationData.id}`;

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getSubscriptionsByOrganisation(organisation_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = organisation_id
    ? null
    : JSON.parse(sessionStorage.getItem("organisationDetails"))[0];
  // console.log("organisation_id", organisation_id);
  const url = organisation_id
    ? `subscriptions/getSubscriptionByOrganisation?organisation_id=${organisation_id}`
    : `subscriptions/getSubscriptionByOrganisation?organisation_id=${userOrganisationData.id}`;

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });
  return response;
}

export async function getProductByOrganisationType() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];

  const response = await fetch(
    `${baseUrl}/subscriptions/getProductByOrganisationType?type=${userOrganisationData.type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );
  return response;
}

export async function createCheckoutSession(product) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/organisations/createCheckoutSession`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
      body: JSON.stringify(product),
    }
  );

  return response;
}

export async function getOrganisations() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/organisations/getOrganisations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    console.log("responsedata", responseData);
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getOrganisationById(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/organisations/getOrganisationById?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    console.log("responsedata", responseData);
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function createStripeAccountByOrganisationAndUser(
  organisation_id,
  user_id
) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/organisations/createStripeAccountByOrganisationAndUser?organisation_id=${organisation_id}&user_id=${user_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    console.log("responsedata", responseData);
    return responseData;
  } else {
    console.error("Failed to create stripe id:", response.statusText);
  }
}

export async function createOrganisation(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id
    ? "organisations/updateOrganisation"
    : "organisations/createOrganisation";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create organisation:", response.statusText);
  }
}

export async function updateOrganisation(organisation) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/organisations/updateOrganisation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(organisation),
  });

  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to update organisation:", response.statusText);
  // }
}

export async function deleteOrganisation(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/organisations/deleteOrganisation?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (!response.ok) {
    // Navigate to homescreen after successful submission
    // const responseData = await response.json(); // Parse the response data as JSON
    console.error("Failed to fetch:", response.statusText);
  }
  return response;
}

export async function createCourse(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "courses/updateCourse" : "courses/createCourse";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create course:", response.statusText);
  }
}

export async function getCourses() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/courses/getCourses`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getArchivedCourses() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/courses/getArchivedCourses`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getArchivedWebinars() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/webinars/getArchivedWebinars`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getAllArchivedResearchPapersByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/researchPapers/getAllArchivedResearchPapersByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getArchived6DResearchPapers() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  
  const response = await fetch(
    `${baseUrl}/researchPapers/getArchived6DResearchPapers`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getAllArchivedPdfDocumentsByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/pdfDocuments/getAllArchivedPdfDocumentsByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function getArchived6DPdfDocuments() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  
  const response = await fetch(
    `${baseUrl}/pdfDocuments/getArchived6DPdfDocuments`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    return responseData;
  } else {
    console.error("Failed to fetch:", response.statusText);
  }
}

export async function deleteCourse(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/courses/deleteCourse?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  if (!response.ok) {
    // Navigate to homescreen after successful submission
    // const responseData = await response.json(); // Parse the response data as JSON
    // // console.log(responseData);
    console.error("Failed to delete course:", response.statusText);
  }
  return response;
}

export async function getUserCoursesByUser(userId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/userCourses/getUserCoursesByUser?user_id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch courses:", response.statusText);
  }
}

export async function getUserWebinarsByUser(userId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/userWebinars/getUserWebinarsByUser?user_id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch user webinars:", response.statusText);
  }
}

export async function getAvailableWebinars() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/webinars/getAvailableWebinars`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch user webinars:", response.statusText);
  }
}

export async function getUsersByLocation(locationId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/users/getUsersByLocation?location_id=${locationId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getCourseCompletionsByLocation(locationId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/courses/getCourseCompletionsByLocation?location_id=${locationId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function getCourseAverageScoresByLocation(locationId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  // console.log(userOrganisationData)
  const response = await fetch(
    `${baseUrl}/courses/getCourseAverageScoresByLocation?location_id=${locationId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create webinar:", response.statusText);
  }
}

export async function createUser(user) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/users/createUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(user),
  });

  return response;
}

export async function updateUser(user) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/users/updateUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(user),
  });

  return response;
}

export async function deleteUser(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/users/deleteUser?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  return response;
}

export async function createLocation(location) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = location.id
    ? "locations/updateLocation"
    : "locations/createLocation";
  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(location),
  });

  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create location:", response.statusText);
  // }
}

export async function deleteLocation(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/locations/deleteLocation?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
  });

  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   // const responseData = await response.json(); // Parse the response data as JSON
  //   // // console.log(responseData);
  //   // return responseData;
  // } else {
  //   console.error("Failed to delete location:", response.statusText);
  // }
}

export async function moveUsers(selectedLocation, targetLocation) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/users/moveUsers?from_location_id=${selectedLocation}&to_location_id=${targetLocation}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  return response;
  // if (response.ok) {
  //   return;
  //   // Navigate to homescreen after successful submission
  //   // const responseData = await response.json(); // Parse the response data as JSON
  //   // // console.log(responseData);
  //   // return responseData;
  // } else {
  //   console.error("Failed to move users:", response.statusText);
  // }
}

export async function get6DResearchPapers() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/researchPapers/get6DResearchPapers`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get research paper:", response.statusText);
  }
}

export async function getAllResearchPapersByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/researchPapers/getAllResearchPapersByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get research paper:", response.statusText);
  }
}

export async function deleteResearchPaper(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/researchPapers/deleteResearchPaper?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (!response.ok) {
    console.error("Failed to delete research paper:", response.statusText);
  }
  return response;
}

export async function getSixdHandbooks() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(`${baseUrl}/sixdHandbooks/get6DSixdHandbooks`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get 6D Handbooks:", response.statusText);
  }
}

export async function getAllSixdHandbooksByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/sixdHandbooks/getAllSixdHandbooksByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get 6D Handbooks:", response.statusText);
  }
}

export async function getPdfDocuments() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
 
  const response = await fetch(`${baseUrl}/pdfDocuments/get6DPdfDocuments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get Pdf Documents:", response.statusText);
  }
}

export async function getAllPdfDocumentsByOrganisation() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const userOrganisationData = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  const response = await fetch(
    `${baseUrl}/pdfDocuments/getAllPdfDocumentsByOrganisation?organisation_id=${userOrganisationData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get Pdf Documents:", response.statusText);
  }
}

export async function createResearchPaper(researchPaper) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const url = researchPaper.id
    ? "researchPapers/updateResearchPaper"
    : "researchPapers/createResearchPaper";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(researchPaper),
  });
  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create Reserach Paper:", response.statusText);
  // }
}

export async function createSixdHandbook(handbook) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const url = handbook.id
    ? "sixdHandbooks/updateSixdHandbook"
    : "sixdHandbooks/createSixdHandbook";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(handbook),
  });

  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create 6D Handbook:", response.statusText);
  // }
}

export async function deleteSixdHandbook(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/sixdHandbooks/deleteSixdHandbook?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (!response.ok) {
    console.error("Failed to delete research paper:", response.statusText);
  }

  return response;
}

export async function createPdfDocument(pdfDocument) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const url = pdfDocument.id
    ? "pdfDocuments/updatePdfDocument"
    : "pdfDocuments/createPdfDocument";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(pdfDocument),
  });

  return response;
  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create PdfDocument:", response.statusText);
  // }
}

export async function deletePdfDocumment(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/pdfDocuments/deletePdfDocument?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (!response.ok) {
    console.error("Failed to delete research paper:", response.statusText);
  }
  return response;
}

export async function getModulesByCourse(courseId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/modules/getModulesByCourse?course_id=${courseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch modules:", response.statusText);
  }
}

export async function getModulesByUserCourse(userCourseId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/modules/getModulesByUserCourse?user_course_id=${userCourseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch modules:", response.statusText);
  }
}

export async function getCompletedModulesByUserCourse(userCourseId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/completedModules/getCompletedModulesByUserCourse?user_course_id=${userCourseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch modules:", response.statusText);
  }
}

export async function getLessonsByModuleAndUser(moduleId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/lessons/getLessonsByModuleAndUser?module_id=${moduleId}&user_id=${sessionUserData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch lessons:", response.statusText);
  }
}

export async function getQuizByModuleId(moduleId) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/quizzes/getQuizByModuleId?module_id=${moduleId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  return response;
}

export async function markQuiz(quizData) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/quizzes//markQuiz`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
      body: JSON.stringify(quizData),
    }
  );

  return response;
}

export async function createCompletedLesson(completedLesson) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/completedLessons/setCompletedLesson`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
      body: JSON.stringify(completedLesson),
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create completed lesson:", response.statusText);
  }
}

export async function getUpcomingWebinarsByUser() {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));
  const response = await fetch(
    `${baseUrl}/webinars/getUpcomingWebinarsByUser?user_id=${sessionUserData.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to fetch upcoming webinars:", response.statusText);
  }
}

export async function createUserWebinar(userWebinar) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/userWebinars/createUserWebinar`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(userWebinar),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create user webinar:", response.statusText);
  }
}

export async function createQuiz(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "quizzes/updateQuiz" : "quizzes/createQuiz";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to create quiz:", response.statusText);
  }
}

export async function getQuizByModule(module_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/quizzes/getQuizByModuleId?module_id=${module_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get quiz:", response.statusText);
  }
}

export async function createQuestion(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id
    ? "questions/updateQuestion"
    : "questions/createQuestion";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create question:", response.statusText);
  // }
  return response;
}

export async function deleteQuestion(id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(`${baseUrl}/questions/deleteQuestion?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`, // Replace YOUR_AUTH_TOKEN with the actual token
    },
  });

  if (!response.ok) {
    console.error("Failed to delete research paper:", response.statusText);
  }
  return response;
}

export async function createAnswer(params) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const url = params.id ? "answers/updateAnswer" : "answers/createAnswer";

  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
    },
    body: JSON.stringify(params),
  });

  // if (response.ok) {
  //   // Navigate to homescreen after successful submission
  //   const responseData = await response.json(); // Parse the response data as JSON
  //   // console.log(responseData);
  //   return responseData;
  // } else {
  //   console.error("Failed to create answer:", response.statusText);
  // }
  return response;
}

export async function getQuestionsByQuiz(quiz_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/questions/getQuestionsByQuiz?quiz_id=${quiz_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get questions:", response.statusText);
    return response;
  }
}

export async function getAnswersByQuestion(question_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/answers/getAnswersByQuestion?question_id=${question_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get Answers:", response.statusText);
    return response;
  }
}

export async function getPossibleAnswersByQuestion(question_id) {
  const sessionUserData = JSON.parse(sessionStorage.getItem("userdetails"));

  const response = await fetch(
    `${baseUrl}/answers/getPossibleAnswersByQuestion?question_id=${question_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionUserData.email_address}:${sessionUserData.password}`,
      },
    }
  );

  if (response.ok) {
    // Navigate to homescreen after successful submission
    const responseData = await response.json(); // Parse the response data as JSON
    // console.log(responseData);
    return responseData;
  } else {
    console.error("Failed to get Answers:", response.statusText);
    return response;
  }
}

export function customerList(status = "active", count = 0) {
  const customers = [
    {
      name: "Loughview Care Home",
      address: "123 Loughview, Lurgan",
      logo: customerIcon1, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    {
      name: "Greenfield NHS Trust",
      address: "45 Green Street, Healthtown",
      logo: customerIcon2, // Replace with actual image
      subscriptionStatus: "active",
    },
    {
      name: "Lakeside Care Home",
      address: "789 Lake View Drive, Watertown",
      logo: customerIcon3, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    {
      name: "Sunnyside Care Home",
      address: "123 Sunshine Lane, Pleasantville",
      logo: customerIcon4, // Replace with actual image
      subscriptionStatus: "inactive",
    },
    // {
    //   name: "Sanjay Kr Das",
    //   address: "123 Barrackpore, India",
    //   logo: "https://via.placeholder.com/150", // Replace with actual image
    //   subscriptionStatus: "active",
    // },
  ];

  if (status === "inactive") {
    const inactiveCustomers = customers.filter(
      (customer) => customer.subscriptionStatus === "inactive"
    );
    const totalInactiveCustomerCount = inactiveCustomers.length;
    return count ? totalInactiveCustomerCount : inactiveCustomers;
  }

  return customers;
}
