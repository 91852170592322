import React, { useEffect, useState } from "react";
import Header from "./Header";
import image1 from "../../images/recordedwebinars/1.png";
import image2 from "../../images/recordedwebinars/2.png";
import image3 from "../../images/recordedwebinars/3.png";
import image4 from "../../images/recordedwebinars/4.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAvailableRecordedWebinars,
  getAvailableWebinars,
  getUserWebinarsByUser,
} from "../../apiFunction/ApiFunctions";
import HeaderComponent from "../admin/shared/HeaderComponent";

const RecordedWebinars = () => {
  const navigate = useNavigate();
  const [userWebinars, setUserWebinars] = useState(null);
  const [recordedWebinars, setRecordedWebinars] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  // const location = useLocation();
  // const { state } = location;
  // console.log(state);

  // const webinars = [
  //   {
  //     title: "When someone wants to leave",
  //     presenter: "Dr. Emily Johnson",
  //     date: "June 15, 2022",
  //     duration: "1 hour 30 mins",
  //     image: image1,
  //   },
  //   {
  //     title: "How to effectively engage with carers",
  //     presenter: "Dr. James Smith",
  //     date: "July 5, 2022",
  //     duration: "2 hours",
  //     image: image2,
  //   },
  //   {
  //     title: "Unrecognised pain",
  //     presenter: "Dr. Sarah Adams",
  //     date: "July 20, 2022",
  //     duration: "1 hour 45 mins",
  //     image: image3,
  //   },
  //   {
  //     title: "Supporting with personal care",
  //     presenter: "Dr. Emily Johnson",
  //     date: "June 16, 2022",
  //     duration: "1 hour 20 mins",
  //     image: image4,
  //   },
  // ];

  useEffect(() => {
    // getUserWebinarsByUser(user.id)
    //   .then((data) => {
    //     console.log("userwebinars", data);
    //     setIsLoading(false);
    //     setUserWebinars(data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching webinars:", error);
    //   });

    getAvailableWebinars()
      .then((data) => {
        console.log("recordedwebinars", data);
        setIsLoading(false);
        setRecordedWebinars(data);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
      });
  }, []);

  return (
    <div className="min-h-screen  bg-white">
      <HeaderComponent />
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-2 ">Recorded Live Webinars</h2>

        {isLoading && (
          <div role="status" className="ml-[50%]">
            <svg
              aria-hidden="true"
              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {recordedWebinars && recordedWebinars.length === 0 && (
          <h2 className="text-3xl mt-4 mb-2 ">No Recorded Webinars!</h2>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {recordedWebinars &&
            recordedWebinars.map((webinar, index) => (
              <div
                key={webinar.id}
                className="bg-white h-[540px]  p-4 rounded-lg shadow-lg"
              >
                <div className="h-[55%] mb-4">
                  <img
                    src={`https://66-dementia.b-cdn.net/test/webinars/images/${
                      webinar.id
                    }?${new Date().getTime()}`}
                    alt={webinar.title}
                    className="h-full w-full object-cover rounded-lg mb-4"
                  />
                </div>
                <div className="p-4 h-[35%]">
                  <div className="h-2/4">
                    <h3 className="text-xl  font-bold mb-2">{webinar.title}</h3>
                  </div>
                  <p className="text-gray-700 mb-1">By: {webinar.presenter}</p>
                  <p className="text-gray-700 mb-1">
                    Date: {new Date(webinar.date_time).toLocaleDateString()}
                  </p>
                  <p className="text-gray-700 mb-2">
                    Duration: {webinar.duration_minutes} minutes
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="bg-blue-900 text-white  px-8 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                      onClick={() => {
                        navigate("/mediaplayer", {
                          state: {
                            type: "webinar",
                            title: webinar.title,
                            subTitle: webinar.description,
                            videoId: webinar.webinar_video_bunny_id,
                            presenter: webinar.presenter,
                          },
                        });
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecordedWebinars;
