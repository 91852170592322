// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createCourse,
  createQuiz,
  createWebinarVideo,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const CreateQuizPage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  const [id, setId] = useState(state ? state.id : null);
  const [passMark, setPassMark] = useState("");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(state ? state.image : "");

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  useEffect(() => {
    // setCourseList(trainingCourses());
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Prepare the form data
    const formData = {
      id: state ? state.id : null,
      module_id: state.module_id,
      pass_mark: passMark,
      image:
        image && image.includes("https://") ? "" : image?.split(",")[1] || null,
    };

    createQuiz(formData)
      .then(async (response) => {
        setLoading(false);
        console.log(response);
        if (response) {
          // const responseData = await response.json(); // Parse the response data as JSON
          // console.log("quiz", responseData);

          navigate("/add-question", {
            state: {
              quiz_id: response.id,
            },
          });
        } else {
          // const errorText = await response.text();
          // console.error("Failed to create user:", errorText);
          console.log(await response.text());
          setError("Failed to Create Quiz");
        }
      })
      .catch((error) => {
        console.error("Error updating quiz:", error);
      });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-4xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            <>{state.id ? "Update" : "Create"}</> New Quiz
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Course:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              value={state.course_name}
              required
              readOnly
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Module:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              value={state.module_title}
              required
              readOnly
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Pass Mark:</label>
            <select
              className="border border-gray-300 rounded-lg p-2 w-48 mt-1"
              value={[passMark]}
              onChange={(e) => setPassMark(e.target.value)}
              required
            >
              <option value="">Pass Mark</option>
              <option value="100">100%</option>
              <option value="90">90%</option>
              <option value="80">80%</option>
              <option value="70">70%</option>
            </select>
          </div>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded relative w-1/3 ml-[33.33%] text-center mt-2"
              role="alert"
            >
              <span className="block  sm:inline">Error : {error}</span>
            </div>
          )}

          <div className="flex space-x-8">
            <div className="flex flex-col items-center">
              <div className="mb-4">
                {image ? (
                  <img
                    src={image}
                    alt="Logo"
                    className="w-32 h-32 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500 p-2">
                      Upload Image for Quiz
                    </span>
                  </div>
                )}
              </div>
              <label
                htmlFor="logo-upload"
                className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
              >
                {state.id ? "Change" : "Upload"} Image
              </label>
              <input
                id="logo-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="bg-gray-300 text-black py-3 px-6 rounded-lg font-medium w-32 h-12 mr-4"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                "Generate Quiz"
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateQuizPage;
